// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.calendarView .colornull, .grantedList .color0 {
    border-left:4px solid #00ac00;
    background-color: #c9ffc9  !important;
}

.calendarView .color1, .grantedList .color1 {
    border-left:4px solid #ff8800;
    background-color: #ffe4c9 !important;
}


.calendarView .color2, .grantedList .color2 {
    border-left:4px solid #0616a3;
    background-color: #daddef !important;
}



.calendarView .color3, .grantedList .color3  {
    border-left:4px solid #a3062b;
    background-color: #efdae0 !important;
}

.calendarView .color4, .grantedList .color4  {
    border-left:4px solid #86054f;
    background-color: #dfc3ea !important;
}
`, "",{"version":3,"sources":["webpack://./src/app/ui/calendar-view/color.css"],"names":[],"mappings":";AACA;IACI,6BAA6B;IAC7B,qCAAqC;AACzC;;AAEA;IACI,6BAA6B;IAC7B,oCAAoC;AACxC;;;AAGA;IACI,6BAA6B;IAC7B,oCAAoC;AACxC;;;;AAIA;IACI,6BAA6B;IAC7B,oCAAoC;AACxC;;AAEA;IACI,6BAA6B;IAC7B,oCAAoC;AACxC","sourcesContent":["\r\n.calendarView .colornull, .grantedList .color0 {\r\n    border-left:4px solid #00ac00;\r\n    background-color: #c9ffc9  !important;\r\n}\r\n\r\n.calendarView .color1, .grantedList .color1 {\r\n    border-left:4px solid #ff8800;\r\n    background-color: #ffe4c9 !important;\r\n}\r\n\r\n\r\n.calendarView .color2, .grantedList .color2 {\r\n    border-left:4px solid #0616a3;\r\n    background-color: #daddef !important;\r\n}\r\n\r\n\r\n\r\n.calendarView .color3, .grantedList .color3  {\r\n    border-left:4px solid #a3062b;\r\n    background-color: #efdae0 !important;\r\n}\r\n\r\n.calendarView .color4, .grantedList .color4  {\r\n    border-left:4px solid #86054f;\r\n    background-color: #dfc3ea !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
