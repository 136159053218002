import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ModalService} from '../../_modal';

@Component({
  selector: 'time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: TimePickerComponent,
    multi: true
  }]
})
export class TimePickerComponent implements OnInit, ControlValueAccessor {
  public overlayId: string;
  public hourStart = 6;
  public showHour: boolean = false;
  public showMinutes: boolean = false;
  public  hourSelected = 0;
  public currentHour: string;
  public currentMinute: string;
  @Input() public ngModel:string;
  @Output() public ngModelChange = new EventEmitter<string>();
  private storedTime: string = '';


  modelChanged(event) {
    event.target.value = this.addColonIfMissing(event.target.value);
    if(this.checkTime(event.target.value) == false ) { return; }
    this.ngModelChange.emit(event.target.value);
  }

  constructor(public message: ModalService) { }

    writeValue(value: any): void {
        this.ngModel = value;
    }
    registerOnChange(fn: any): void {
       // throw new Error('Method not implemented.');
    }
    registerOnTouched(fn: any): void {
        //throw new Error('Method not implemented.');
    }
    setDisabledState?(isDisabled: boolean): void {
        //throw new Error('Method not implemented.');
    }

  ngOnInit(): void {
    const dateNow = new Date();
    this.overlayId = + dateNow + '_' + Math.floor((Math.random() * 9999) + 1000);
  }

  selectHour(hour: any, finish) {
    this.hourSelected = 1;
    hour = hour.toString();
    if(hour.length==1) { hour = '0' + hour; }
    this.ngModel = hour + ':00';
    if(finish) {
      if(this.checkTime(this.ngModel)) { this.ngModelChange.emit(this.ngModel); }
      this.showHour = false;
      this.showMinutes = false;
    } else {
      this.showHour = true;
      this.showMinutes = true;
    }
    this.separateTime();
    setTimeout(() => {
      this.hourSelected = 0; return;
    }, 150);
  }

  moveHour(direction: string) {
    setTimeout(() => {

      window.document.getElementById('timePicker' + this.overlayId).focus();
      this.hourSelected = 1;
      if( direction == 'up') {
        if(this.hourStart==6) { this.hourStart=0; }
        if(this.hourStart==11) { this.hourStart=6; }
      } else if( direction == 'down') {
        if(this.hourStart==6) { this.hourStart=11; }
        if(this.hourStart==0) { this.hourStart=6; }
      }
      setTimeout(() => {
        this.hourSelected = 0;
      }, 300);
    }, 50);
  }

  selectMinute(minutes: string) {
    this.hourSelected = 0;
    this.ngModel = this.ngModel.split(':')[0] + ':' + minutes;
    if(this.checkTime(this.ngModel)) { this.ngModelChange.emit(this.ngModel); }
    this.showMinutes = false;
    this.showHour = false;
  }

  activateHourOverlay() {
    this.hourStart=6;
    this.showHour = true;
    if(this.ngModel!='') {    this.showMinutes = true;}
    this.separateTime();
  }

  separateTime() {
    const splitTime = this.ngModel.split(':');
    this.currentHour = splitTime[0];
    this.currentMinute = splitTime[1];
  }

  deactivateOverlays() {
    this.storedTime = '';
    setTimeout(() => {
      if(this.hourSelected == 1) { this.hourSelected = 0; return; }
      this.ngModel = this.addColonIfMissing(this.ngModel);
      if(this.checkTime(this.ngModel)) { this.ngModelChange.emit(this.ngModel); }
      this.showHour = false;
      this.showMinutes = false;
    }, 200);

  }

  public addColonIfMissing(value:string) {
    console.log('1__' + value);
    let returnValue = value;
    if(value.includes(':')==false) { if(value.length==4) {  returnValue = value.substring(0,2) + ':' + value.substring(2,4); } }
    return returnValue
  }

  public checkTime(value) {
    let error = 0;
    if(value.includes(':')==false) { error=1; }
    const timeParts = value.split(':');
    if(timeParts.length>2 ) { error=1; }
    if((timeParts[0]>-1 && timeParts[0]<24) == false) { error=1; }
    if((timeParts[1]>-1 && timeParts[1]<60) == false) { error=1; }
    if(error==1) {
      if(this.storedTime!='') { this.ngModel = this.storedTime; this.storedTime=''; }
      this.message.open('timeWarning');
      return false;
    }
    return true;
  }

  closeWarning() {
    this.message.close('timeWarning');
    document.getElementById('timePicker' + this.overlayId).focus();
  }

  manualEnter($event: KeyboardEvent) {
    if(this.storedTime == '' && Number($event.key)>=0 && this.ngModel.length>3) {
      this.storedTime = this.ngModel;
      this.ngModel = '' + $event.key;
    }

  }
}
