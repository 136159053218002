// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `input[type=password]
{
    border-radius: 3px !important;
    border: 1px solid #ceced2 !important;
    background-color: #ffffff !important;
    color: #000 !important;
    height:20px !important;
    font-size: 12px !important;
    padding: 1px .2em !important;
    display: inline-block !important;
    vertical-align: middle !important;
    margin-top: -1px !important;
}

.priorityArrowDown
{
    margin-left: 22px;
}

.eMailModal {
    display: none;
    position: fixed;
    z-index: 1;
    padding-top: 100px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.4);
}

.eMailModal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    border-radius: 5px 5px 5px 5px;
    width: 30%;
}


`, "",{"version":3,"sources":["webpack://./src/app/core/user-manager/user-manager.component.css"],"names":[],"mappings":"AAAA;;IAII,6BAA6B;IAC7B,oCAAoC;IACpC,oCAAoC;IACpC,sBAAsB;IACtB,sBAAsB;IACtB,0BAA0B;IAC1B,4BAA4B;IAC5B,gCAAgC;IAChC,iCAAiC;IACjC,2BAA2B;AAC/B;;AAEA;;IAEI,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,eAAe;IACf,UAAU;IACV,kBAAkB;IAClB,OAAO;IACP,MAAM;IACN,WAAW;IACX,YAAY;IACZ,cAAc;IACd,4BAA4B;IAC5B,iCAAiC;AACrC;;AAEA;IACI,yBAAyB;IACzB,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,8BAA8B;IAC9B,UAAU;AACd","sourcesContent":["input[type=password]\r\n{\r\n    -webkit-border-radius: 3px !important;\r\n    -moz-border-radius: 3px !important;\r\n    border-radius: 3px !important;\r\n    border: 1px solid #ceced2 !important;\r\n    background-color: #ffffff !important;\r\n    color: #000 !important;\r\n    height:20px !important;\r\n    font-size: 12px !important;\r\n    padding: 1px .2em !important;\r\n    display: inline-block !important;\r\n    vertical-align: middle !important;\r\n    margin-top: -1px !important;\r\n}\r\n\r\n.priorityArrowDown\r\n{\r\n    margin-left: 22px;\r\n}\r\n\r\n.eMailModal {\r\n    display: none;\r\n    position: fixed;\r\n    z-index: 1;\r\n    padding-top: 100px;\r\n    left: 0;\r\n    top: 0;\r\n    width: 100%;\r\n    height: 100%;\r\n    overflow: auto;\r\n    background-color: rgb(0,0,0);\r\n    background-color: rgba(0,0,0,0.4);\r\n}\r\n\r\n.eMailModal-content {\r\n    background-color: #fefefe;\r\n    margin: auto;\r\n    padding: 20px;\r\n    border: 1px solid #888;\r\n    border-radius: 5px 5px 5px 5px;\r\n    width: 30%;\r\n}\r\n\r\n\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
