// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.currentSelectedCanContainArticles
{
    font-weight: bold !important;
}

.productGroupOverlayOpen
{
    overflow:hidden;
    visibility:visible;
    top:0px;
    left:0px;
    right:0px;
    bottom:0px;
    position: fixed;
    z-index: 9999998;
    background: none;
}

.closeOverlay
{
    position: fixed;
    visibility: hidden;
    top:0px;
    bottom:0px;
}

.selectable
{
    font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/app/erp/erp-product-group/erp-product-group.component.css"],"names":[],"mappings":"AAAA;;IAEI,4BAA4B;AAChC;;AAEA;;IAEI,eAAe;IACf,kBAAkB;IAClB,OAAO;IACP,QAAQ;IACR,SAAS;IACT,UAAU;IACV,eAAe;IACf,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;;IAEI,eAAe;IACf,kBAAkB;IAClB,OAAO;IACP,UAAU;AACd;;AAEA;;IAEI,iBAAiB;AACrB","sourcesContent":[".currentSelectedCanContainArticles\r\n{\r\n    font-weight: bold !important;\r\n}\r\n\r\n.productGroupOverlayOpen\r\n{\r\n    overflow:hidden;\r\n    visibility:visible;\r\n    top:0px;\r\n    left:0px;\r\n    right:0px;\r\n    bottom:0px;\r\n    position: fixed;\r\n    z-index: 9999998;\r\n    background: none;\r\n}\r\n\r\n.closeOverlay\r\n{\r\n    position: fixed;\r\n    visibility: hidden;\r\n    top:0px;\r\n    bottom:0px;\r\n}\r\n\r\n.selectable\r\n{\r\n    font-weight: bold;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
