// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.time_picker {

}

.marked_hour {
    background-color: #ffe1b3;
    border-bottom: 1px solid lightgrey;
    border-top: 1px solid lightgrey;
}

.time_picker .time_select_box{
    width: calc(100% - 2px );
    text-align: right;
    padding-right: 6px;
    display: table-caption;
    padding-bottom: 1px;
    padding-top: 1px;
    cursor: pointer;
}

.time_picker .time_select_box:hover{
    background: lightgrey;
}


.time_picker .time_select_arrow{
    height:18px;
    width:24px;
    text-align: center;
}

.time_picker .time_select_arrow:hover{
    background: lightgrey;
}

.time_picker .pickerOverlayHour {
    position:absolute;
    font-size: 10px;
    z-index: 999999;
    background: #ffffff;
    width: 24px;
    height:221px;
    margin-left: 58px;
    margin-top:-105px;
    border:1px solid #808080;
}

.time_picker .pickerOverlayMinutes {
    position:absolute;
    font-size: 10px;
    z-index: 999999;
    background: #ffffff;
    width: 24px;
    height:56px;
    margin-left: 84px;
    margin-top:-40px;
    border:1px solid gray;
}
`, "",{"version":3,"sources":["webpack://./src/app/ui/time-picker/time-picker.component.css"],"names":[],"mappings":"AAAA;;AAEA;;AAEA;IACI,yBAAyB;IACzB,kCAAkC;IAClC,+BAA+B;AACnC;;AAEA;IACI,wBAAwB;IACxB,iBAAiB;IACjB,kBAAkB;IAClB,sBAAsB;IACtB,mBAAmB;IACnB,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,qBAAqB;AACzB;;;AAGA;IACI,WAAW;IACX,UAAU;IACV,kBAAkB;AACtB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,iBAAiB;IACjB,eAAe;IACf,eAAe;IACf,mBAAmB;IACnB,WAAW;IACX,YAAY;IACZ,iBAAiB;IACjB,iBAAiB;IACjB,wBAAwB;AAC5B;;AAEA;IACI,iBAAiB;IACjB,eAAe;IACf,eAAe;IACf,mBAAmB;IACnB,WAAW;IACX,WAAW;IACX,iBAAiB;IACjB,gBAAgB;IAChB,qBAAqB;AACzB","sourcesContent":[".time_picker {\r\n\r\n}\r\n\r\n.marked_hour {\r\n    background-color: #ffe1b3;\r\n    border-bottom: 1px solid lightgrey;\r\n    border-top: 1px solid lightgrey;\r\n}\r\n\r\n.time_picker .time_select_box{\r\n    width: calc(100% - 2px );\r\n    text-align: right;\r\n    padding-right: 6px;\r\n    display: table-caption;\r\n    padding-bottom: 1px;\r\n    padding-top: 1px;\r\n    cursor: pointer;\r\n}\r\n\r\n.time_picker .time_select_box:hover{\r\n    background: lightgrey;\r\n}\r\n\r\n\r\n.time_picker .time_select_arrow{\r\n    height:18px;\r\n    width:24px;\r\n    text-align: center;\r\n}\r\n\r\n.time_picker .time_select_arrow:hover{\r\n    background: lightgrey;\r\n}\r\n\r\n.time_picker .pickerOverlayHour {\r\n    position:absolute;\r\n    font-size: 10px;\r\n    z-index: 999999;\r\n    background: #ffffff;\r\n    width: 24px;\r\n    height:221px;\r\n    margin-left: 58px;\r\n    margin-top:-105px;\r\n    border:1px solid #808080;\r\n}\r\n\r\n.time_picker .pickerOverlayMinutes {\r\n    position:absolute;\r\n    font-size: 10px;\r\n    z-index: 999999;\r\n    background: #ffffff;\r\n    width: 24px;\r\n    height:56px;\r\n    margin-left: 84px;\r\n    margin-top:-40px;\r\n    border:1px solid gray;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
