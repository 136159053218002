import { formatNumber } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import {SettingsService} from "../../services/settings.service";
import {ModalService} from "../../_modal";
import {CalendarViewComponent} from "../calendar-view/calendar-view.component";
import {fromEvent, Observable, Subscription} from "rxjs";

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.css','../calendar-view/color.css']
})
export class CalendarComponent implements OnInit, OnDestroy {
  public OverlayActive = false;
  public overlayId: string;
  public toolTipContent: any;
  public httpOptions = { };
  public data: any[];
  public filteredData: any[];
  public viewDateForm = false;
  public formData: any;
  public calendarGranted: [ { rights: any, name: any, solo: number,color: any,visible: any,id: any,fk_user_owner: any}];
  public dataReminder: any;
  public viewAllCalendarGranted = false;
  public processDataForNewDate: {tid,id,titel,recurring_id} = null;
  public dateToDelete: any;
  public timeBoxData: any;
  public currentSelectedSettingsTab = 1;
  public calendarReleased: any;
  public calenderNotReleased: any;
  public newCalenderToRelease = { id: '-1', right: '1' };
  public dataReminderSelectable: any;
  public editorWarningString: string;

  @ViewChild('sideCalendar1' , {static: false}) sideCalendar1: CalendarViewComponent ;
  @ViewChild('sideCalendar2' , {static: false}) sideCalendar2: CalendarViewComponent ;
  @ViewChild('sideCalendar3' , {static: false}) sideCalendar3: CalendarViewComponent ;
  @ViewChild('mainCalendar'  , {static: false}) mainCalendar:  CalendarViewComponent ;

  @HostListener('document:keydown ', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      // ESC schließt den Termin Editor wenn offen. Sonst den Kalender.
      if(this.viewDateForm) { this.closeDateEditForm() } else { this.deactivateOverlay(); }
    }
  }

  resizeObservable$: Observable<Event>
  resizeSubscription$: Subscription
  dropdownOptionsMonthOrYear: [{ name: string; id: number }, { name: string; id: number }];


  constructor(public http: HttpClient,
              public settings: SettingsService,
              public message: ModalService,
              public router: Router,
              public ref: ChangeDetectorRef) {
    this.httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', token: settings.sessionId }) };
    this.overlayId = + Date.now() + '_' + Math.floor((Math.random() * 9999) + 1000);
    this.settings.calendar = this;
  }

  ngOnInit(): void {
    this.resizeObservable$ = fromEvent(window, 'resize')
    this.resizeSubscription$ = this.resizeObservable$.subscribe( event => this.resizeAction(event))
    this.loadDates();
    this.loadRemindType();
    this.loadTimebox();
  }

  ngOnDestroy() {
    this.resizeSubscription$.unsubscribe()
  }

  private resizeAction(_event: Event) {
    this.sideCalendar1.changeMode(11);
    this.sideCalendar2.changeMode(11);
    this.sideCalendar3.changeMode(11);
    setTimeout(() => {

      //this.sideCalendar1.changeMode(11);
      //this.sideCalendar2.changeMode(11);
      //this.sideCalendar3.changeMode(11);


    }, 200);

  }

  public initCalendarGranted() {
    this.http.post<{ status: any, message: any, data: [{ rights: any; name: any; solo: number; color: any; visible: any; id: any; fk_user_owner: any; }] }>(this.settings.restBaseUrl + 'calendar/granted', {}, this.settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.calendarGranted = data.data;
          this.calendarGranted.unshift({ name:'Mein Kalender',solo:0,color:'0',visible:'1',id:'0',fk_user_owner:this.settings.data.userId, rights:2 });
        }
    );
  }

  public initCalendarReleased() {
    this.http.post<{ status: any, message: any, data: any }>(this.settings.restBaseUrl + 'calendar/released', {}, this.settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.calendarReleased = data.data;
        }
    );
  }

  public initCalendarNotReleased() {
    this.http.post<{ status: any, message: any, data: any }>(this.settings.restBaseUrl + 'calendar/released', {'invert':1}, this.settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.calenderNotReleased = data.data;
        }
    );
  }

  public filterDate() {
    // is show all
    if(this.viewAllCalendarGranted==true) {
      this.filteredData = this.data;
      return;
    }
    // if one is set to solo then filter for this user
    const soloCalendar = this.calendarGranted.filter(item => item.solo == 1);
    if( soloCalendar.length > 0) {
      this.filteredData = this.data.filter(item => item.fk_user_owner == soloCalendar[0].fk_user_owner);
      return;
    }
    let visibleCalendar = [];
    this.calendarGranted.forEach(  (calendar) => {
      if(calendar.visible) {
        visibleCalendar.push(calendar.fk_user_owner);
      }
    })
    this.filteredData = this.data.filter(item => visibleCalendar.includes(item.fk_user_owner));
  }

  viewDate(data: any) {

  }

  newDate(timeForNewDate: { day: any; month: any; year: string; hour: any; minute: any; }) {
    this.initFormData();
    this.buildReminderSelectable();
    if( this.processDataForNewDate != null) {
      this.formData.tid = this.processDataForNewDate.tid;
      this.formData.name = this.processDataForNewDate.titel;
      if(this.processDataForNewDate.recurring_id>0) { this.formData.hide_process = 1;}
    }
    this.formData.startDate = this.formData.endDate  = formatNumber(Number(timeForNewDate.day), 'de-DE', '2.0') + '.' + formatNumber(Number(timeForNewDate.month), 'de-DE', '2.0') + '.' + timeForNewDate.year;
    this.formData.timeStart = formatNumber(Number(timeForNewDate.hour)  , 'de-DE', '2.0') + ':' + formatNumber(Number(timeForNewDate.minute), 'de-DE', '2.0');
    // :75 verhindern
    let endHour:number = Number(timeForNewDate.hour);
    let endMinute:number = Number(timeForNewDate.minute)+30;
    if(endMinute > 60) { endMinute = endMinute - 60; endHour++;}
    this.formData.timeEnd   = formatNumber(endHour, 'de-DE', '2.0') + ':' + formatNumber(endMinute, 'de-DE', '2.0');
    this.viewDateForm = true;
  }

  editDate(date: { [x: string]: any; }) {
    delete(date['reminderArray']);
    // Ermitteln, ob nur Lesen Recht, dann Abbrechen
    if(!this.mainCalendar.checkCalendarRight(date)) { alert('Sie haben nur Lesezugriff auf diesen Kalender.'); return; }
    this.initFormData();
    this.formData = date;
    this.formData.newReminder = '0';
    let reminder = [];
    if(this.formData.reminderArray == undefined) { this.formData.reminderArray = []; }
    if(this.formData.reminder != undefined && this.formData.reminderArray.length < 1 ) {
      // Erinnerungsdaten wandeln und in separate Array schreiben
        let rowData: string[];
        let reminderData = this.formData.reminder.split(',');
        reminderData.forEach( (row: string) => {
          rowData = row.split(';');
          reminder.push( { id:rowData[0],name: this.getReminderName(rowData[1]),fk_date_remind_type:rowData[1],sms:(rowData[2] == '1') });
        })
      this.formData.reminderArray = reminder;
    }
    this.buildReminderSelectable();
    this.viewDateForm = true;
  }

  buildReminderSelectable() {
    if(this.formData.reminderArray && this.formData.reminderArray.length<1) {
      this.dataReminderSelectable = this.dataReminder; return;
    }
      this.dataReminderSelectable  = this.dataReminder.filter( (possibleReminder: any)=>{
        return  this.formData.reminderArray.filter( (selectedReminder: any) =>{
          return selectedReminder.name == possibleReminder.name;
        }).length == 0
      });
  }

  toggleOverlay() {
    if (this.OverlayActive === false) { this.activateOverlay(); } else { this.deactivateOverlay(); }
  }

  public createDateFromProcess(tid: string) {
    this.http.get<any>(this.settings.restBaseUrl + 'process/calendar/' + tid, this.settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          console.log(data);
          this.processDataForNewDate = data;
          this.toggleOverlay();
        }
    );
  }


  activateOverlay(): void {
    this.OverlayActive = true;
    this.initCalendarGranted();
  }

  loadDates() {
    this.http.post<{status: any, message: any, data: any[]}>(this.settings.restBaseUrl + 'calendar', {}, this.settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.data = data.data;
          this.filteredData = this.data;
        }
    );
  }

  deactivateOverlay(): void {
    this.OverlayActive = false;
    this.message.close('calenderSettings');
    this.processDataForNewDate=null;
  }

  saveDateTime(date: any) {
    this.http.put<any>(this.settings.restBaseUrl + 'calendar/time', JSON.stringify(date) , this.httpOptions)
        .pipe(map(data => data)).subscribe(
        () => {

        }
    );
  }

  moveSideCalendar(number: number) {
    this.sideCalendar1.changeViewArea(number);
    this.sideCalendar2.changeViewArea(number);
    this.sideCalendar3.changeViewArea(number);
    this.mainCalendar.updateTimeRangeSend();
  }

  changeMainCalendarTo(date: any) {
    // Side Kalender Klick ändert den Hauptkalender
    this.mainCalendar.setDate(date.day,date.month,date.year)
  }

  saveDate() {
    // Titel prüfen
    if(this.formData.name == '') {
      this.editorWarningString = "Sie müssen einen Titel angeben um den Termin zu Speichern!";
      this.message.open('editorWarning');
      return;
    }
    // Zeit prüfen
    // Enddatum vor Startdatum
    // Endzeit vor Startzeit am gleichen Tag

    if(this.formData.name == '') {
      this.editorWarningString = "Sie müssen einen Titel angeben um den Termin zu Speichern!";
      this.message.open('editorWarning');
      return;
    }

    this.http.put<any>(this.settings.restBaseUrl + 'calendar', JSON.stringify(this.formData) , this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.processDataForNewDate = null;
          if(data.data != undefined) {
            if(this.formData.id < 1) {
              // Neuer Eintrag
              for(let i= this.data.length-1;i>-1;i--) {
                if(this.data[i].start_time < data.data[0].start_time) {
                  this.data.splice(i+1 , 0 , data.data[0]);
                  break;
                }
              }
              this.filteredData = this.data;
            } else {
              // Bearbeitet, daher update des Termins
              for(let i= this.data.length-1;i>-1;i--) {
                if(this.data[i].id == data.data[0].id) {
                  this.data[i] = data.data[0];
                  break;
                }
              }
            }
            setTimeout(() => {
              this.mainCalendar.recalculateDatePositions();
              this.closeDateEditForm();
            }, 200);
          }
        }
    );
  }

  formAddReminder() {
    this.formData.reminderArray.push( { id:-1,name: this.getReminderName(this.formData.newReminder),fk_date_remind_type:this.formData.newReminder,sms:false } );
    this.buildReminderSelectable();
    setTimeout(() => {
      this.formData.newReminder = '0';
    }, 50);
  }

  public initFormData() {
    this.formData = {id:'',fk_user_owner:this.settings.data.userId ,name:'',text:'', full_day: '', hide_process: '',
                      recurringMode:0 , recurringCount:1 , recurringWeekday:{mo:0,di:0,mi:0,do:0,fr:0,sa:0,so:0}, recurringEndType:0, recurringEndDate:'', recurringEndCount:1, recurringName:'',
                      reminder:[], reminderArray:[], newReminder:0,startDate:'',startTime:'',endDate:'',endTime:''};
  }

  public loadRemindType() {
    if(Array.isArray(this.dataReminder) && this.dataReminder.length>0) { return; }
    this.http.post<{ status: any, message: any, data: any }>(this.settings.restBaseUrl + 'calendar/remind/type', {}, this.settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.dataReminder = data.data;
          this.buildReminderSelectable();
        }
    );
  }

  closeDateEditForm() {
    this.viewDateForm = false;
    this.formData = null;
  }

  changeViewAllCalendarGranted() {
    this.clearCalendarGrantedToSolo();
    this.viewAllCalendarGranted = !this.viewAllCalendarGranted;
    this.filterDate();
  }

  changCalendarGrantedVisibility(calendar: { visible: boolean; }) {
    calendar.visible = !calendar.visible;
    this.filterDate();
  }

  setCalendarGrantedToSolo(calendar: { solo: number; }) {
    this.viewAllCalendarGranted = false;
    if(calendar.solo != 1) {
      this.clearCalendarGrantedToSolo();
      calendar.solo = 1;

    } else {
      this.clearCalendarGrantedToSolo();
    }
  this.filterDate();
  }

  clearCalendarGrantedToSolo() {
    this.calendarGranted.forEach( (calendar) => {
      calendar.solo = 0;
    })
  }

  cancelDeleteDate() {
    this.dateToDelete = null;
    this.message.close('deleteDate');
  }

  checkDeleteDate(date: any) {
    this.dateToDelete = date;
    this.message.open('deleteDate');
  }

  deleteDate() {
    this.http.delete<{ status: any, message: any, data: any }>(this.settings.restBaseUrl + 'calendar/' + this.dateToDelete.id, this.settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        () => {
          // Löschendes des Termins aus der data Liste und filterliste neu aufbauen
          for (let i = 0; i < this.data.length; i++) {
            if (this.data[i].id === this.dateToDelete.id ) {
              this.data.splice(i, 1);
            }}
          this.filterDate();
          this.dateToDelete = null;
        }
    );
    this.message.close('deleteDate');
  }

  test(timeStart: any) {
    alert(timeStart);
  }

  showCalendarSettings() {
    this.initCalendarReleased();
    this.initCalendarNotReleased();
    this.message.open('calenderSettings');
  }

  changeSettingsTab(number: number) {
    this.currentSelectedSettingsTab = number;
  }

  deleteGrantedCalendar(calendar: { name: any; solo: any; color: any; visible: any; id: string; fk_user_owner: any }) {
    this.http.delete<{ status: any, message: any, data: any }>(this.settings.restBaseUrl + 'calendar/granted/' + calendar.id  ,this.settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        () => {
          // Löschen des Termins aus der data Liste und filterliste neu aufbauen
          for (let i = 0; i < this.calendarGranted.length; i++) {
            if (this.calendarGranted[i].id === calendar.id ) {
              this.calendarGranted.splice(i, 1);
            }}
        }
    );
  }

  deleteReleasedCalendar(calendar: any) {
    this.http.delete<{ status: any, message: any, data: any }>(this.settings.restBaseUrl + 'calendar/released/' + calendar.id  ,this.settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        () => {
          // Löschen des Termins aus der data Liste und filterliste neu aufbauen
          for (let i = 0; i < this.calendarReleased.length; i++) {
            if (this.calendarReleased[i].id === calendar.id ) {
              this.calendarReleased.splice(i, 1);
              this.initCalendarNotReleased();
            }}
        }
    );
  }

  addToCalendarReleased() {
    if(this.newCalenderToRelease.id == '-1') {
      alert('Sie müssen einen Benutzer aus der Liste auswählen um diesem Ihren Kalender freizugeben!');
      return;
    }
    this.http.put<{ status: any, message: any, data: any }>(this.settings.restBaseUrl + 'calendar/released/' + this.newCalenderToRelease.id + '/' + this.newCalenderToRelease.right, {} ,this.settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          // Löschen des Termins aus der data Liste und filterliste neu aufbauen
          let newCalendarReleaseName = '';
          for (let i = 0; i < this.calenderNotReleased.length; i++) {
            if (this.calenderNotReleased[i].id === this.newCalenderToRelease.id ) {
              newCalendarReleaseName = this.calenderNotReleased[i].name;
              this.calenderNotReleased.splice(i, 1);
            }}
            this.calendarReleased.push( { id:data.message, name:newCalendarReleaseName, color:0, rights: this.newCalenderToRelease.right } );
            this.newCalenderToRelease.id = '-1';
            this.newCalenderToRelease.right = '1';
        }
    );
  }

  timeRangeSelectionChanged(newRange: any) {
    // Range setzen. Kalender scrollen automatisch da hin.
    this.sideCalendar1.setRangeMarking(newRange);
    this.sideCalendar2.setRangeMarking(newRange);
    this.sideCalendar3.setRangeMarking(newRange);
  }

  getReminderName(id: string) {
    let name = '';
    for(let i= 0; i < this.dataReminder.length;i++) {
      if( this.dataReminder[i].id == id ) { name = this.dataReminder[i].name; break; }
    }
    return name;
  }

  removeReminder(x: any) {
    // Erinnerung entfernen
    this.http.delete<{ status: any, message: any, data: any }>(this.settings.restBaseUrl + 'calendar/remind/' + x.id, this.settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        () => {
          this.formData.reminderArray.splice(this.formData.reminderArray.indexOf(x), 1);
          this.buildReminderSelectable();
        }
    );

  }

  private loadTimebox() {
    if(Array.isArray(this.timeBoxData) && this.timeBoxData.length>0) { return; }
    this.http.post<{ status: any, message: any, data: any }>(this.settings.restBaseUrl + 'calendar/timeBox', {}, this.settings.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.timeBoxData = data.data;
        }
    );
  }

  public viewProcess(tid: any) {
    this.router.navigate(['/process/' + tid + '/view/6/1']);
    this.deactivateOverlay();
  }


  dropDateOnSideCalendar($event: { month: string; year: string; day: string }) {
    if(this.mainCalendar.currentSelectedDateItem==undefined) { return; }

    // wenn source

    // reset date move

    // move date to target day

    // open main calendar on target day/week
  }

  swapRecurringWeekday(day: string) {
    if(this.formData.recurringWeekday[day] == 0 ) { this.formData.recurringWeekday[day] = 1; }
    else { this.formData.recurringWeekday[day] = 0; }
    this.generateRecurringName();
  }

  generateRecurringName() {
    let newText = ''
    switch(this.formData.recurringMode) {
      case '0':
        // Keine Wiederholung
        newText = 'Nicht wiederholen';
        break;
      case '1':
        // Tage
        if(this.formData.recurringCount==1) { newText = 'Findet jeden Tag statt'; }
        else { newText = 'Findet alle ' + this.formData.recurringCount +' Tage statt'; }
        break;
      case '2':
        // Wochen
        newText = 'Findet ';
        if(this.formData.recurringCount>1) {         newText += 'alle ' + this.formData.recurringCount + ' Wochen ' ; }
        newText += 'jeden ';
        let cw = this.formData.recurringWeekday;
        let dayCount : {max:number, current:number} = { current:1, max:Number(cw.mo) + Number(cw.di) + Number(cw.mi) + Number(cw.do) + Number(cw.fr) + Number(cw.sa) + Number(cw.so), };
        // Wenn kein Tag vorbelegt, dann automatisch den Wochentag des Startdatums setzen
        if(dayCount.max == 0) {
          const splitDate = this.formData.startDate.split('.');
          cw[new Date( splitDate[2], splitDate[1], splitDate[0] ).toLocaleString('de-DE', {weekday: 'short'}).toLowerCase()]=1;
        }
        if(cw.mo) { newText += 'Montag'     + this.addDaySeparator(dayCount); }
        if(cw.di) { newText += 'Dienstag'   + this.addDaySeparator(dayCount); }
        if(cw.mi) { newText += 'Mittwoch'   + this.addDaySeparator(dayCount); }
        if(cw.do) { newText += 'Donnerstag' + this.addDaySeparator(dayCount); }
        if(cw.fr) { newText += 'Freitag'    + this.addDaySeparator(dayCount); }
        if(cw.sa) { newText += 'Samstag'    + this.addDaySeparator(dayCount); }
        if(cw.so) { newText += 'Sonntag'    + this.addDaySeparator(dayCount); }
        newText += ' statt ';
        break;
      case '3':
        // Monate
          this.dropdownOptionsMonthOrYear = [
            {id:1, name:'Am xy des Monats'},
            {id:2, name:'Am dritten Donnerstag des Monates'}
          ];
        break;
      case '4':
        // Jahr
        this.dropdownOptionsMonthOrYear = [
          {id:1, name:'Am xy des Jahres'},
          {id:2, name:'Am dritten Donnerstag Im Januar'}
        ];
        break;
    }
    this.formData.recurringName = newText;
  }

  addDaySeparator(dayCount: {max:number, current:number}) {
    if( dayCount.max < 2 ) { return ''; }
    if( dayCount.current == dayCount.max ) { return ' '; }
    if( dayCount.current == dayCount.max-1 ) { dayCount.current++; return ' und '} else { dayCount.current++; return ', '}
  }
  changeRecurringMode() {
    if(this.formData.recurringMode == 0 ) {  }
    this.generateRecurringName();
    this.message.open('editorRecurring');
  }
}


