import { Component, OnInit, ViewChild  } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ContextMenuService, ContextMenuComponent } from '@perfectmemory/ngx-contextmenu';;
import { SettingsService } from '../../services/settings.service';
import { ModalService } from '../../_modal';
import { Router } from '@angular/router';

@Component({
  selector: 'app-template-clean',
  templateUrl: './template-clean.component.html',
  styleUrls: ['./template-clean.component.css']
})
export class TemplateCleanComponent implements OnInit {

  public rightContainerWidth;
  public leftContainerWidth;
  public httpOptions = { };
  public editorWarningString = '';

  constructor(public http: HttpClient,
              public settings: SettingsService,
              public message: ModalService,
              public router: Router) {
    this.setRightContainerWidth(40);
  }

  ngOnInit(): void {
  }

  setRightContainerWidth(width): void  {
    this.rightContainerWidth = width;
    this.leftContainerWidth = 100 - this.rightContainerWidth;
  }


  goTo(selector) {
    const destination = selector.options[selector.selectedIndex].value;
    this.router.navigate(['/' + destination]);
  }

  handleQuestionAction() {
        // auf den Fragedialog reagieren
  }
}
