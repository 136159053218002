import {Component, Input, Output, OnInit, OnChanges, EventEmitter} from '@angular/core';

@Component({
  selector: '[app-uitemplate]',
  templateUrl: './uitemplate.component.html',
  styleUrls: ['./uitemplate.component.css']
})
export class UitemplateComponent implements OnInit, OnChanges {

  // Die untere Struktur aktiviert das 2 Wege Databinding
  // im Element muss die externe Variable so zugewiesen werden: [(model)]="variable"
  // um die Variablen Änderung nach Außen zu kommunizieren muss wie in der unten stehenden
  // Methode changeModel() die Methode this.modelChange.emit('VALUE');


  public overlayId: string;
  public OverlayActive = false;

  // MODEL
  public modelValue = '123';
  @Output() modelChange: EventEmitter<string>;

  @Input()
  get model() {
    return this.modelValue;
  }
  set model(value: string) {
    this.ngOnChanges(value);
  }

  ngOnChanges(value) {
    if ( typeof value !== 'string' ) { return; }
    this.modelValue = value;
  }

  constructor() {
    this.modelChange = new EventEmitter();
  }

  changeModel(value): void {
    this.modelValue = value;
    this.modelChange.emit(this.modelValue);
  }
  // ENDE MODEL


  ngOnInit() {
    const dateNow = new Date();
    this.overlayId = + dateNow + '_' + Math.floor((Math.random() * 9999) + 1000);
  }

  activateOverlay(): void {
    this.OverlayActive = true;
    document.getElementById(this.overlayId).style.width = '250px';
    document.getElementById(this.overlayId).style.height = '255px';
    document.getElementById(this.overlayId).style.visibility = 'visible';
  }

  deactivateOverlay(): void {
    this.OverlayActive = false;
    setTimeout(() => {
      document.getElementById(this.overlayId).style.visibility = 'hidden';
    }, 100);
  }

  toggleOverlay() {
    if (this.OverlayActive === false) { this.activateOverlay(); } else { this.deactivateOverlay(); }
  }
}
