import {formatNumber} from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {SettingsService} from '../../services/settings.service';


const enum Status {
  OFF = 0,
  RESIZE = 1,
  PREPARE_MOVE = 2,
  MOVE = 3
}

@Component({
  selector: 'app-calendar-view',
  templateUrl: './calendar-view.component.html',
  styleUrls: ['./calendar-view.component.css','./color.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: CalendarViewComponent,
    multi: true
  }]
})
export class CalendarViewComponent implements OnInit, OnDestroy, ControlValueAccessor {

  public monthsNames = [ "Januar", "Februar", "März", "April", "Mai", "Juni", "July", "August", "September", "Oktober", "November", "Dezember" ];

  public disabled: boolean;
  private viewJustLoaded = true;
  public overlayId: string;
  public OverlayActive = false;
  public displayMode;
  public calenderDays: any[];

  // View settings
  @Input() public viewNavigation:boolean;
  @Input() public viewMode: number; // 0 List, 1 - 9 Tage 1 bis 9, 10 zwei Wochen, 11 Monat,12 Jahr, 13 Waagerechte Zeitleiste
  @Input() public small: boolean;
  @Input() public followRange: number;

  public getViewMode() {
    return this.viewMode;
  }

  public rangeAreaText: String;
  public getRangeAreaText() {
    return this.rangeAreaText;
  }
  @Input() public month:any;
  @Input() public granted:any;
  @Input() public timeBoxData:any;

  @Input() public ngModel: [{ 'dateKey','dayStart','monthStart','yearStart','position','layer'}];
  @Output() public ngModelChange = new EventEmitter<[{'dateKey'}]>();
  modelChanged() {
    this.ngModelChange.emit(this.ngModel);
  }

  // return methods
  @Output() public view = new EventEmitter<{date, event }>();
  public clockInterval: number;

  viewDate(event:any) {

    if(this.viewMode<5 || this.viewMode>10) { return; } // Nur in den passenden Tagesmodus öffnen
    let timeoutTime = 0;
    if(this.detailViewIsVisible == false) { timeoutTime=50;this.detailViewIsVisible = true; }
    setTimeout(() => {
      let el = event.target;
      while(el.parentNode.className != 'calendarView') {
        el = el.parentNode;
      }
      this.scrollArea.getBoundingClientRect();
      let posX = el.offsetLeft;
      let posY = el.offsetTop;
      if( posX > ( this.scrollArea.getBoundingClientRect().width / 2 ) ) {
        posX -= 390;
      } else {
        posX += el.offsetWidth;
      }
      let element: HTMLElement = document.getElementById('calendarViewInfoContainer');
      element.style.visibility = 'visible';
      element.style.left = posX + 'px';
      element.style.top = posY + 'px';
    }, timeoutTime);
    this.ref.detectChanges();
  }

  @Output() public new = new EventEmitter<{day, month, year, hour, minute}>();
  newDate(dayCount , hour, elementClicked)  {
    let percentPosition = (100 / elementClicked.target.offsetHeight) * elementClicked.offsetY;
    let day = this.getDatePlusDays(dayCount,'day');
    let month = this.getDatePlusDays(dayCount,'month');
    let year = this.getDatePlusDays(dayCount,'year');
    let minute;
    if(percentPosition < 25) { minute = 0; } else if(percentPosition >= 25 && percentPosition < 50) { minute = 15; }
    else if(percentPosition >= 50 && percentPosition <= 75) { minute = 30; } else if(percentPosition >= 75) { minute = 45; }
    this.new.emit({day, month, year, hour, minute});
  }

  @Output() public drop = new EventEmitter<{ month: string; year: string; day: string }>();
  dropDate(date: { month: string; year: string; day: string }) {this.drop.emit(date); }

  @Output() public process = new EventEmitter<[{'tid'}]>();
  viewProcess(tid) { this.process.emit(tid); }

  @Output() public edit = new EventEmitter<[{'dateKey'}]>();
  editDate(date) { this.detailViewIsVisible = false; this.edit.emit(date); }

  @Output() public save = new EventEmitter<[{'dateKey'}]>();
  saveDateTime(date) { this.save.emit(date); }

  @Output() public delete = new EventEmitter<any>();
  deleteDate(date) { this.delete.emit(date); }

  @Output() public select = new EventEmitter<[{'dateKey'}]>();
  setSelection(date) { this.select.emit(date); }

  @Output() public changeTimeRange = new EventEmitter<any>();
  changeTimeRangeSend(range) { this.changeTimeRange.emit(range); }

  // Ui parameters
  public resizeStartPosition = { x:0, y:0 };
  public resizeStartEndTime: any;
  public scrollArea: HTMLElement;
  public resizeStartStartTime: any;
  public resizeStartPositionDay: any;
  public currentSelectedDateItem: any;
  public currentTimeSeconds: any;
  public selectedDay;
  public selectedMonth;
  public selectedYear;
  public currentYear;
  public currentMonth;
  public kw;
  public todayDate;
  public weekOfYear: number;
  public first: boolean;
  public yearViewData = [];
  public year: number;
  public currentDay: any;
  public dayCellHeight: number;
  public dayCellWidth = 227;
  public dayNames = ['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So'];
  public monthNamesShort = ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'];
  public monthNamesLong = ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'];
  public yearRows = 3;
  public yearColumns = 4;
  public elementName: string;
  @HostListener('window:resize', ['$event'])
  onResize(_event: any) {
    this.closeDetailView();
    if(this.viewMode>0 && this.viewMode<11) {this.updateView()};
  }

  // Maus und Bewegung Variablen
  public mouse: {x: number, y: number}
  public status: Status = Status.OFF;
  public debug: any;
  public detailViewIsVisible = false;

  @HostListener('window:mousemove', ['$event'])
  onMouseMove(event: MouseEvent){
    this.mouse = { x: event.clientX, y: event.clientY };
    if(this.status !== Status.OFF) this.doChangeDateItem();
  }
  @HostListener('window:mouseup', ['$event'])
  onMouseUp(event: MouseEvent){
    // @ts-ignore
    let dropTarget: string = event.target.id.split('_');
    if(dropTarget[0] == this.elementName) {
      this.dropDate({'day':dropTarget[1], 'month':String(this.currentMonth), 'year':String(this.currentYear)});
      return;
    }
      if(this.status != Status.OFF) {
      if(this.status == Status.RESIZE) {
        this.saveDateTime(this.currentSelectedDateItem);
      }
      this.status = Status.OFF;
      this.recalculateDatePositions()
    }



  }

  constructor( public ref: ChangeDetectorRef, public settings: SettingsService,public element: ElementRef) {
    this.elementName = this.element.nativeElement.getAttribute("name");
    if(this.displayMode < 1) {     this.displayMode = 3; }
    this.currentMonth = 6;
    this.currentYear = 2022;
    this.year = 2022;
    let month;
    let days;
    let daysInCurrentMonth;
    let calendarWeek = 1;
    let dayCounterForCalendarWeek = 1;
    let currentDayNameIndex = new Date(this.currentYear,0, 1).getDay()-1;
    if(currentDayNameIndex===0) { currentDayNameIndex=7; }
    let lastCalendarWeek = 0;
    let calendarWeekChanged = 0;
    for(let x = 1; x <= 12; x++) {
      days=[];
      daysInCurrentMonth = new Date(this.year, x, 0).getDate();
      for(let y = 1; y <= 31; y++) {
        if(y <= daysInCurrentMonth) { currentDayNameIndex++; }
        if(currentDayNameIndex>7) currentDayNameIndex = 1;
        if(lastCalendarWeek !== calendarWeek) {
          lastCalendarWeek = calendarWeek;
          calendarWeekChanged = 1;
        }
        days.push({ day: y, dayName: this.dayNames[currentDayNameIndex-1], calendarWeek, calendarWeekChanged});
        calendarWeekChanged = 0;
        if(dayCounterForCalendarWeek >= 7) { dayCounterForCalendarWeek = 1; calendarWeek++; } else { dayCounterForCalendarWeek ++; }
      }
      month = { month: x, monthName: this.monthNamesShort[x-1], daysInCurrentMonth, days: JSON.parse(JSON.stringify(days))}
      this.yearViewData.push(month);
    }
  }

  public setRangeMarking(newRange: any) {
    // Ermitteln, ob der aktuelle Anzeigebereich den newRange enthält. Falls Nein dann return.
    // todo: view mode mit einbeziehen > Auch auf letzten Anzeigetag prüfen

    // todo anzeige in view rücken
    if(this.followRange!=undefined) {
      // prüfen, ob rang außerhalb, dann bewegen

        let yearMonthShift = Number(String(newRange.year) + String(  formatNumber(Number(newRange.month + this.followRange)  , 'de-DE', '2.0')));
        let yearMonthNow = Number(String(this.currentYear) + String(formatNumber(Number(this.currentMonth + this.followRange)  , 'de-DE', '2.0')));

        //if( yearMonthShift > yearMonthNow ) { this.changeViewArea(1); }
        //else if( yearMonthShift < yearMonthNow )  { this.changeViewArea(-1); }
    }
    // Letzten Tag des neuen Bereichs ermitteln
    let currentDateObj = new Date(newRange.year, newRange.month-1, newRange.day);
    let newDateObj = new Date(currentDateObj.getTime() + ( (newRange.viewMode-1) * 60 * 60 * 24 * 1000));
    let newRangeLastDay = Number(newDateObj.getDate());
    let newRangeLastMonth = Number(newDateObj.getMonth() + 1);
    let newRangeLastYear = Number(newDateObj.getFullYear());

    if( !(newRange.year == this.currentYear && newRange.month == this.currentMonth || newRangeLastYear == this.currentYear && newRangeLastMonth == this.currentMonth) ) {
      this.calenderDays.forEach(function (row) {
        row.forEach(function (day) {
            day.marked = 0;
        });
      });
      return;
    }
    // Wenn Ja, dann den Bereich markieren
    this.calenderDays.forEach( (row) => {
      row.forEach( (day) =>{
        if(
            (
              (
                  day.day >= newRange.day && day.day <= (newRange.day+newRange.viewMode-1) && day.month == newRange.month && day.year == newRange.year
              )
              ||
              (
                  newRangeLastMonth != newRange.month && newRangeLastYear == this.currentYear && newRangeLastMonth == this.currentMonth && day.day <= newRangeLastDay  && day.day >= 0
              )
            )
            && day.currentMonth == 1) {
          day.marked = 1;
        } else {
          day.marked = 0;
        }
      });
    });
    this.ref.detectChanges();
  }

  writeValue(_obj: any): void {
        //throw new Error('Method not implemented.');
    }
    registerOnChange(_fn: any): void {
      //throw new Error('Method not implemented.');
    }
    registerOnTouched(_fn: any): void {
      //throw new Error('Method not implemented.');
    }
    setDisabledState?(_isDisabled: boolean): void {
      //throw new Error('Method not implemented.');
    }

  ngOnInit(): void {
    this.dayCellHeight = 65;
    if( this.viewMode == undefined || this.viewMode < 0 || this.viewMode > 13 ) { alert('viewMode MUSS übergeben werden!');}
    const dateNow = new Date();
    this.overlayId = + dateNow + '_' + Math.floor((Math.random() * 9999) + 1000);
    const todayDay = dateNow.getDate();
    let todayDayString = todayDay.toString();
    if ( todayDay < 10) { todayDayString = '0' + todayDay.toString(); }
    const todayMonth = (dateNow.getMonth() + 1);
    let todayMonthString = todayMonth.toString();
    if (todayMonth < 10) { todayMonthString = '0' + todayMonth.toString(); }
    this.todayDate = {
      day: todayDay,
      dayString: todayDayString,
      dayStart: todayDay,
      month: todayMonth,
      monthString: todayMonthString,
      monthStart: todayMonth,
      year: dateNow.getFullYear(),
      yearStart: dateNow.getFullYear()};
    if(this.month && this.month>0 && this.month!='next' && this.month!='prev') { this.currentMonth = this.month; } else { this.currentMonth = todayMonth; }
    if(this.month && this.month=='next') { this.currentMonth++ }
    if(this.month && this.month=='prev') { this.currentMonth-- }
    this.currentYear = dateNow.getFullYear();
    this.currentDay = todayDay;
    if( this.viewNavigation!=false ) {this.viewNavigation = true;}
    if (this.first === undefined) { this.first = false; }
    this.setCurrentTime();
    if(this.followRange > 0) { this.changeViewArea(this.followRange); }
    // @ts-ignore
    this.clockInterval = setInterval(() => { this.setCurrentTime(); }, 5000);
    setTimeout(() => { this.updateView(); }, 50);
    setTimeout(() => { this.recalculateDatePositions()}, 100);
    setTimeout(() => { this.changeTimeRangeSend({day:this.currentDay,month:this.currentMonth,year:this.currentYear,viewMode:this.viewMode});}, 100);
  }

  setCurrentTime() {
    const timeNow = new Date();
    this.currentTimeSeconds = (timeNow.getHours() * 3600) + (timeNow.getMinutes() * 60) + timeNow.getSeconds() ;
  }

  ngOnDestroy() {
    clearInterval(this.clockInterval);
  }

  public updateTimeRangeSend() {
    this.changeTimeRangeSend({day:this.currentDay,month:this.currentMonth,year:this.currentYear,viewMode:this.viewMode});
  }

  public changeViewArea(direction) {
    // Je nach aktuellem Anzeigemodus und der direction (1/-1) vorwärts oder rückwärts in der Anzeige gehen
    if(this.viewMode>0 && this.viewMode < 11) {
      // viewMode Tage Bewegen
      let newDateObj = new Date(this.currentYear, this.currentMonth-1, this.currentDay);
      newDateObj.setDate(newDateObj.getDate() + (direction * this.viewMode));
      this.currentDay = Number(newDateObj.getDate());
      this.currentMonth = Number(newDateObj.getMonth() + 1);
      this.currentYear = Number(newDateObj.getFullYear());
    } else if(this.viewMode==11) {
      // Einen Monat Bewegen
      this.currentMonth = Number(this.currentMonth);
      this.currentMonth += direction;
      if (this.currentMonth < 1)       { this.currentMonth = 12; this.currentYear --; }
      else if (this.currentMonth > 12) { this.currentMonth = 1;  this.currentYear ++; }
      this.monthView(this.currentMonth);
    } else if(this.viewMode==12) {
      // Einen Jahr Bewegen
      this.currentYear += direction;
    }
    this.updateView();
    this.changeTimeRangeSend({day:this.currentDay,month:this.currentMonth,year:this.currentYear,viewMode:this.viewMode});
    this.ref.detectChanges();
  }

  public changeMode(to: number) {
    this.displayMode = to;
  }

  public setDate(day,month,year) {
    // Monats Sprung prüfen
    if(month == 13) { month = 1; year++;}
    this.currentDay = day;
    this.currentMonth = month;
    this.currentYear = year;
    this.updateView();
    this.changeTimeRangeSend({day:this.currentDay,month:this.currentMonth,year:this.currentYear,viewMode:this.viewMode});
  }

  selectDayAndMonth(day, month) {
    this.currentDay = day;
    this.currentMonth = month;
    this.updateView();
    this.changeMode(3); // Later to last selected
    this.changeTimeRangeSend({day:this.currentDay,month:this.currentMonth,year:this.currentYear,viewMode:this.viewMode});
  }

  dragAndDropItem(_event: any) {

  }

  changeViewMode(mode: number) {
    this.viewMode = mode;
    this.changeTimeRangeSend({day:this.currentDay,month:this.currentMonth,year:this.currentYear,viewMode:this.viewMode});
    setTimeout(() => { this.updateView(); }, 10);
  }

  updateView() {
    this.closeDetailView();
    // 0 List, 1 - 9 Tage 1 bis 9, 10 zwei Wochen, 11 Monat,12 Jahr, 13 Waagerechte Zeitleiste
    if(this.viewMode==0) {
      this.listView();
    } else if( this.viewMode >= 1 && this.viewMode <= 10) {
      this.dayView();
    } else if(this.viewMode==11) {
      this.monthView(this.currentMonth);
    }else if(this.viewMode==12) {
      this.yearView();
    }else if(this.viewMode==13) {
      this.timelineView();
    }
    this.createRangeAreaText();
    this.ref.detectChanges();
  }

  public viewToday() {
    this.currentDay = this.todayDate.day;
    this.currentMonth = this.todayDate.month;
    this.currentYear = this.todayDate.year;
    this.updateView();
    this.changeTimeRangeSend({day:this.currentDay,month:this.currentMonth,year:this.currentYear,viewMode:this.viewMode});
  }

  public scrollToHour(hour) {
      const div = document.getElementById('calendarViewScrollArea'+this.overlayId);
      const scrollTo = (hour * (this.dayCellHeight + 2)) - 50;
      div.scrollTo({ top: scrollTo });
  }

  public moveCurrentDateToPreviousMonday() {
    // Abstand zum letzten Montag ermitteln
    let currentDateObj = new Date(this.currentYear, this.currentMonth-1, this.currentDay);
    let weekDay = currentDateObj.getDay();
    if(weekDay == 1) { return;}

      // Wenn nicht Montag dann Startdatum entsprechend verschieben
      let direction = 0;
      if(weekDay > 1) {
        direction = (weekDay - 1) * -1;
      } else if(weekDay == 0) {
        direction = -6;
      }
      // Startdatum verschieben
      let newDateObj = new Date(currentDateObj.getTime() + (direction * 60 * 60 *24 * 1000));
      this.currentDay = newDateObj.getDate();
      this.currentMonth = newDateObj.getMonth() + 1;
      this.currentYear = newDateObj.getFullYear();
  }

  public dayView() {
    if(this.viewMode == 7 ) { this.moveCurrentDateToPreviousMonday(); this.updateWeekOfYear(); }
    this.changeDayCellWidth();
    this.scrollToHour(7);
    if(this.viewJustLoaded) {
      this.viewJustLoaded = false;
      setTimeout(() => { this.recalculateDatePositions()}, 300);
    } else {
      this.recalculateDatePositions();
    }
  }

  public listView() {
    setTimeout(() => {
      const date = new Date();
      const element = document.getElementById('monthMark_' + formatNumber(date.getMonth()+1,'de-DE', '2.0') + '_' + date.getFullYear());
      element.scrollIntoView();
    }, 200);
  }

  public yearView() {

  }

  public timelineView() {

  }

  monthView(month) {
    let numberOfFirstDayInCurrentMonth = new Date(this.currentYear,month - 1, 1 ).getDay();
    if (numberOfFirstDayInCurrentMonth === 0 ) { numberOfFirstDayInCurrentMonth = 7; }
    let nextMonth = month + 1;
    let nextYear = this.currentYear;
    if (nextMonth === 13) { nextMonth = 1; nextYear ++; }

    let previousMonth = month - 1;
    let previousYear = this.currentYear;
    if (previousMonth === 0) { previousMonth = 12; previousYear --; }

    const endDayThisMonth = 32 - new Date(this.currentYear, month - 1, 32).getDate() ;
    const endDayPreviousMonth = 32 - new Date(previousYear, previousMonth - 1, 32).getDate();

    let dayCounter = endDayPreviousMonth + 2 - numberOfFirstDayInCurrentMonth;

    const jsonArrFull = [];
    let jsonArrRow = [];
    let currentMonth = 0;
    let monthInsert = previousMonth;
    let yearInsert = previousYear;

    if (endDayPreviousMonth < dayCounter) { currentMonth = 1; dayCounter = 1; monthInsert++; }
    if(monthInsert == 13) {monthInsert = 1; yearInsert++}
    for (let calenderRows = 1; calenderRows <= 6; calenderRows++) {
      for (let day = 1; day <= 7; day++) {
        jsonArrRow.push({
          day: dayCounter,
          currentMonth: currentMonth,
          marked: 0,
          month: monthInsert,
          year: yearInsert
        });
        dayCounter++;
        if (currentMonth === 0 && dayCounter > endDayPreviousMonth) {
          dayCounter = 1; currentMonth = 1; monthInsert = month; yearInsert = this.currentYear;
        } else if (currentMonth === 1 && dayCounter > endDayThisMonth) {
          dayCounter = 1; currentMonth = 0; monthInsert = nextMonth; yearInsert = nextYear;
        }
      }
      jsonArrFull.push( jsonArrRow );
      jsonArrRow = [];
    }
    this.calenderDays = jsonArrFull;
  }

  filterDateArray(month,year) {
    if (this.ngModel==undefined) return [];
    return this.ngModel.filter(item =>Number(item.monthStart) == Number(month) && item.yearStart == year);
  }

  getDatePlusDays(days, format) {
    let currentDateObj = new Date(this.currentYear, this.currentMonth-1, this.currentDay);
    let newDateObj = new Date(currentDateObj.getTime() + (days * 60 * 60 *24 * 1000));
    let weekday = newDateObj.getDay() -1;
    if(weekday==-1) weekday = 6;
    let returnValue;
    if(format == 'string') {
      returnValue = this.dayNames[weekday] + ' ' + formatNumber(newDateObj.getDate(),'de-DE', '2.0')  + '.' + formatNumber(newDateObj.getMonth() + 1,'de-DE', '2.0') + '.' + newDateObj.getFullYear();
    } else if(format == 'year') {
      returnValue = newDateObj.getFullYear();
    } else if(format == 'month') {
      returnValue = newDateObj.getMonth() + 1;
    } else if(format == 'day') {
      returnValue = newDateObj.getDate();
    } if(format == 'number') {
      returnValue = formatNumber(newDateObj.getDate(),'de-DE', '2.0')  + '.' + formatNumber(newDateObj.getMonth() + 1,'de-DE', '2.0') + '.' + newDateObj.getFullYear();
    }
    return returnValue
  }

  changeDayCellHeight(s: string) {
    if(s=='+') { this.dayCellHeight += 5; } else
    if(s=='-') { this.dayCellHeight -= 5; }
  }

  public updateWeekOfYear() {
    let currentDateObj = new Date(this.currentYear, this.currentMonth-1, this.currentDay);
    let oneJan = new Date(currentDateObj.getFullYear(),0,1);
    // @ts-ignore
    let numberOfDays = Math.floor((currentDateObj - oneJan) / (24 * 60 * 60 * 1000));
    this.weekOfYear = Math.ceil(( currentDateObj.getDay() + 1 + numberOfDays) / 7);
  }

  getTimeAsMathValue(time: string) {
    if(time=='') return;
    const timeParts = time.split(":");
    return (1 / 60 * Number(timeParts[1]) ) + Number(timeParts[0]);
  }

  startDateItemResize(event, item) {
    this.currentSelectedDateItem = item;
    this.status = Status.RESIZE;
    this.resizeStartPosition.y = event.clientY;
    this.resizeStartPosition.x = event.clientX;
    this.resizeStartEndTime = item.timeEnd;
  }

  handelMouseDownEvent(event, item) {
    if(event.button>0) { return;}
    this.currentSelectedDateItem = item;
    this.resizeStartPosition.y = event.clientY;
    this.resizeStartPosition.x = event.clientX;
    let scrollBoxPosition = this.scrollArea.getBoundingClientRect();
    this.resizeStartPositionDay = Math.floor( (event.clientX - scrollBoxPosition.x - 50 ) / this.dayCellWidth);
    this.resizeStartStartTime = item.timeStart;
    this.resizeStartEndTime = item.timeEnd;
    this.status = Status.PREPARE_MOVE;
  }

  public checkCalendarRight(date) {
    // Wenn es der eigene Kalender ist oder man das Recht 2 (Schreiben) besitzt, gebe true zurück.
    return date.fk_user_owner == this.settings.data.userId || this.granted.find((element) => element.fk_user_owner == date.fk_user_owner).rights == 2;
  }

  handleMouseUpEvent(event: MouseEvent) {

    if(event.button>0) { return; }

    if( this.status != Status.MOVE) {
      this.viewDate(event); } else {
      this.saveDateTime(this.currentSelectedDateItem);
    }
    this.status = Status.OFF;
  }

  public doChangeDateItem() {
    let scrollBoxPosition = this.scrollArea.getBoundingClientRect();
    let posX = this.mouse.x + 2;
    let posY = this.mouse.y - scrollBoxPosition.y + 40;

    if(this.status == Status.PREPARE_MOVE && ( Math.abs(posX - this.resizeStartPosition.x)  > (this.dayCellWidth / 2)  ||   Math.abs(posY - this.resizeStartPosition.y)  > (this.dayCellHeight / 2))  ) {
      this.resizeStartPosition.y = posY;
      this.resizeStartPosition.x = posX;
      this.status = Status.MOVE;this.scrollArea.getBoundingClientRect();
      this.detailViewIsVisible = false;
    }

    if( !this.checkCalendarRight(this.currentSelectedDateItem)) { this.status = Status.OFF; alert('Sie haben nur Lesezugriff auf diesen Kalender.');  return; }


    if(this.status != Status.RESIZE && this.status != Status.MOVE) { return; }

    this.currentSelectedDateItem.position = 1;
    this.currentSelectedDateItem.layer = 1;

    let posDiv = (posY - this.resizeStartPosition.y) / this.dayCellHeight;
    let posDivHour = Math.floor(posDiv);
    let posDivMinute = Math.floor((posDiv - posDivHour) / 0.25);

    this.debug = Math.abs(posY - this.resizeStartPosition.y);

    if(this.status == Status.RESIZE) {

      let timePartsEndStart = this.resizeStartEndTime.split(":");
      let newEndHour = Number(timePartsEndStart[0]) + posDivHour;
      let newEndMinute = Number(timePartsEndStart[1]) + (posDivMinute*15);
      // 15 Minuten fix > nicht 04:75 oder 3:-15
      if(newEndMinute >= 60) { newEndMinute = newEndMinute - 60; newEndHour ++; }
      // Ende nicht über 24 Uhr Schieben
      if( (newEndHour == 24 && newEndMinute > 0) || newEndHour > 24 ) { return; }
      // Nicht 0
      if(Number(formatNumber(newEndHour,'de-DE', '2.0') + formatNumber(newEndMinute,'de-DE', '2.0')) <= Number(this.currentSelectedDateItem.timeStart.replace(':',''))) { return;}

      if(posDivHour > 0 || (posDivMinute >= 0 && posDivMinute < 4 ) ) {
        this.currentSelectedDateItem.timeEnd = formatNumber(newEndHour,'de-DE', '2.0') + ':' + formatNumber(newEndMinute,'de-DE', '2.0');
        this.currentSelectedDateItem.end_time   = Math.floor(new Date(this.currentSelectedDateItem.yearStart + '.' + this.currentSelectedDateItem.monthStart + '.' + this.currentSelectedDateItem.dayStart + this.currentSelectedDateItem.timeEnd ).getTime() / 1000);
      } else if (posDivHour < 0) {
        let timePartsStartStart = this.resizeStartStartTime.split(":");
        if(timePartsEndStart[0] > timePartsStartStart[0] ) {
          this.currentSelectedDateItem.timeEnd = formatNumber(newEndMinute,'de-DE', '2.0') + ':' + timePartsEndStart[1];
          this.currentSelectedDateItem.end_time   = Math.floor(new Date(this.currentSelectedDateItem.yearStart + '.' + this.currentSelectedDateItem.monthStart + '.' + this.currentSelectedDateItem.dayStart + ' ' + this.currentSelectedDateItem.timeEnd ).getTime() / 1000);
        }
      }
    } else if(this.status == Status.MOVE) {
      let scrollBoxPosition = this.scrollArea.getBoundingClientRect();
      let currentResizePositionDay = Math.floor( (this.mouse.x - scrollBoxPosition.x - 50 ) / this.dayCellWidth);
      if(currentResizePositionDay != this.resizeStartPositionDay) {
        // Abfangen das man Außerhalb des angezeigten Tagesbereichs verschieben kann.
        if( currentResizePositionDay < 0 || currentResizePositionDay >= this.viewMode ) { return; }
        // Auf einen anderen Tag verschieben
        this.currentSelectedDateItem.dayStart = Number(this.currentSelectedDateItem.dayStart) + Number(currentResizePositionDay - this.resizeStartPositionDay);
        this.currentSelectedDateItem.dateKey = this.currentSelectedDateItem.yearStart + this.currentSelectedDateItem.monthStart + this.currentSelectedDateItem.dayStart;
        this.resizeStartPositionDay = currentResizePositionDay;
        // timestamps aktualisieren
        this.currentSelectedDateItem.end_time   = Math.floor(new Date(this.currentSelectedDateItem.yearStart + '.' + this.currentSelectedDateItem.monthStart + '.' + this.currentSelectedDateItem.dayStart + ' ' + this.currentSelectedDateItem.timeEnd ).getTime() / 1000);
      } else {
        // Innerhalb des Tages verschieben
        let timePartsStartStart = this.resizeStartStartTime.split(":");
        let timePartsEndStart = this.resizeStartEndTime.split(":");
        let newStartHour = Number(timePartsStartStart[0]) + posDivHour;
        let newStartMinute = Number(timePartsStartStart[1]) + (posDivMinute*15);
        let newEndHour = Number(timePartsEndStart[0]) + posDivHour;
        let newEndMinute = Number(timePartsEndStart[1]) + (posDivMinute*15);
        // 15 Minuten fix > nicht 04:75 oder 3:-15
        if(newStartMinute >= 60) { newStartMinute = newStartMinute - 60; newStartHour ++; }
        if(newEndMinute >= 60) { newEndMinute = newEndMinute - 60; newEndHour ++; }
        // Start nicht unter 0 Uhr oder Ende über 24 Uhr Schieben
        if( newStartHour < 0 ) { return; }
        if( (newEndHour == 24 && newEndMinute > 0) || newEndHour > 24 ) { return; }
        // Lesbare Strings aktualisieren
        this.currentSelectedDateItem.timeStart = formatNumber(newStartHour,'de-DE', '2.0') + ':' + formatNumber(newStartMinute,'de-DE', '2.0');
        this.currentSelectedDateItem.dateKey = this.currentSelectedDateItem.yearStart + this.currentSelectedDateItem.monthStart + this.currentSelectedDateItem.dayStart;
        this.currentSelectedDateItem.timeEnd = formatNumber(newEndHour,'de-DE', '2.0') + ':' + formatNumber(newEndMinute,'de-DE', '2.0');
        // timestamps aktualisieren
        this.currentSelectedDateItem.end_time   = Math.floor(new Date(this.currentSelectedDateItem.yearStart + '.' + this.currentSelectedDateItem.monthStart + '.' + this.currentSelectedDateItem.dayStart + ' ' + this.currentSelectedDateItem.timeEnd ).getTime() / 1000);
      }
      this.currentSelectedDateItem.start_time = Math.floor(new Date(this.currentSelectedDateItem.yearStart + '.' + this.currentSelectedDateItem.monthStart + '.' + this.currentSelectedDateItem.dayStart + ' ' + this.currentSelectedDateItem.timeStart ).getTime() / 1000);
    }
  }

  dayViewCalcLeftPosition(date) {
    let Difference_In_Time = new Date(date.yearStart, date.monthStart-1, date.dayStart).getTime() - new Date(this.currentYear, this.currentMonth-1, this.currentDay).getTime();
    let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    let returnValue = 53 + ( Difference_In_Days * this.dayCellWidth );
    if (date.layer > 1) {returnValue += ( ( date.position - 1 ) * (this.dayCellWidth / date.layer ) )+1 }
    return Math.round(returnValue);
  }

  isDateVisibleInSelectedTImeRange(date) {
    let Difference_In_Time = new Date(date.yearStart, date.monthStart-1, date.dayStart).getTime() - new Date(this.currentYear, this.currentMonth-1, this.currentDay).getTime();
    let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    return !(Difference_In_Days < 0 || Difference_In_Days > this.viewMode - 1);
  }

  public changeDayCellWidth() {
    this.scrollArea = document.getElementById('calendarViewScrollArea' + this.overlayId);
    this.dayCellWidth  = (this.scrollArea.clientWidth - 60) / this.viewMode;
  }

  public getCurrentTimePosition() {
    return Math.round(40 + ( ( ( (this.dayCellHeight + 2) * 24) / 86400) * this.currentTimeSeconds ));
  }

  public createRangeAreaText() {
      let newRangeAreaText = '';
      if (this.viewMode > 0 && this.viewMode < 11) {
      if (this.viewMode > 1) { newRangeAreaText += 'Vom '; }
      newRangeAreaText += formatNumber(this.currentDay, 'de-DE', '2.0') + '.' + formatNumber(this.currentMonth, 'de-DE', '2.0') + '.' + this.currentYear;
      if (this.viewMode > 1) { newRangeAreaText += ' bis zum '; }
      newRangeAreaText += this.getDatePlusDays(this.viewMode - 1, 'number');
    }
    if (this.viewMode == 11) {
      newRangeAreaText += formatNumber(this.currentMonth, 'de-DE', '2.0') + '.' + this.currentYear + ' (' + this.monthNamesLong[this.currentMonth-1]  + ']';
    }
    this.rangeAreaText = newRangeAreaText;
  }

  hasDayAnDate(day) {
    if (this.ngModel==undefined) return false;
    return this.ngModel.filter(item => Number(item.dayStart) == Number(day.day) && Number(item.monthStart) == Number(day.month) && item.yearStart == day.year).length > 0;
  }

  getCurrentTime() {
    return formatNumber(Math.floor(this.currentTimeSeconds / 3600), 'de-DE', '2.0') + ':' + formatNumber(Math.floor((this.currentTimeSeconds - (Math.floor(this.currentTimeSeconds / 3600)*3600) ) / 60), 'de-DE', '2.0');
  }

  openProcess(tid: string) {
    this.viewProcess(tid);
  }


  closeDetailView() {
    this.detailViewIsVisible = false;
  }

  recalculateDatePositions() {
    if( this.ngModel == undefined ) { return; }
    let currentDayDatesArray;
    let currentCalcDay
    let currentCalcMonth
    let currentCalcYear
    // Berechnet die Datums Anzeigen für den aktuellen Anzeigezeitraum neu
    // todo performance beim verschieben eventuell nur die betroffenen Tage?

    // Tage durchgehen die neu berechnet werden müssen

    for(let i=0;i<this.viewMode;i++) {
      currentCalcDay = this.getDatePlusDays(i, 'day');
      currentCalcMonth = this.getDatePlusDays(i, 'month');
      currentCalcYear = this.getDatePlusDays(i, 'year');
      // für jeden Tag alle Termine Suchen > Filter
      currentDayDatesArray = this.ngModel.filter(item =>Number(item.dayStart) == Number(currentCalcDay) && item.monthStart == currentCalcMonth && item.yearStart == currentCalcYear);
      currentDayDatesArray.forEach( date => {

        date.layer = 1;
        date.position = 1;

        // Alle Termine am selben Tag durchgehen und mit dem aktuellen Vergleichen.
        currentDayDatesArray.forEach(dateCompare => {
          // Regeln

          // todo: zwei untereinander aber nicht alle parallel

          // 1. Mit mir selbst nicht Vergleichen
          if(date != dateCompare) {
            // 1. Gibt es Termine, die parallel zu diesem sind.
            if( (date.start_time >= dateCompare.start_time && date.start_time < dateCompare.end_time) ||
                (date.end_time > dateCompare.start_time && date.end_time < dateCompare.end_time) ||
                (dateCompare.start_time >= date.start_time && dateCompare.start_time < date.end_time) ||
                (dateCompare.end_time > date.start_time && dateCompare.end_time < date.end_time)
                ){
              // Für jeden parallelen den Layer um 1 erhöhen
              date.layer ++;
              // Wenn die Startzeit vor der eigenen liegt dann eine pos nach rechts
              if (  (date.start_time > dateCompare.start_time)  ) {
                date.position ++;
              }
            }

          }

        });
    });
    }
    this.ref.detectChanges();
  }

  changeKw() {
    let dateOfNewWeekOfYear = new Date(this.currentYear, 0, (2 + (this.weekOfYear - 1) * 7));
    this.currentDay = dateOfNewWeekOfYear.getDate();
    this.currentMonth = dateOfNewWeekOfYear.getMonth() + 1;
    this.currentYear = dateOfNewWeekOfYear.getFullYear();
    this.updateView();
    this.changeTimeRangeSend({day:this.currentDay,month:this.currentMonth,year:this.currentYear,viewMode:this.viewMode});
  }


  changeToWeek() {
    alert('change to week');
  }

  formatDate(date: { yearStart: string, monthStart: string, dayStart: string }) {
    return new Date( Number(date.yearStart), Number(date.monthStart), Number(date.dayStart) ).toLocaleDateString('de-DE', {  day: 'numeric',weekday:"long", month:"long",year:"numeric"}) // "Jul 2021 Friday"
  }
}
