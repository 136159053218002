// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.newMarker {
    background-color: #FFBA75;
    border: 1px solid #FFBA75 !important;
}

.textMarker {
    background-color: blue;
    border: 1px solid blue !important;
}
`, "",{"version":3,"sources":["webpack://./src/app/document/document-assign/document-assign.component.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,oCAAoC;AACxC;;AAEA;IACI,sBAAsB;IACtB,iCAAiC;AACrC","sourcesContent":[".newMarker {\r\n    background-color: #FFBA75;\r\n    border: 1px solid #FFBA75 !important;\r\n}\r\n\r\n.textMarker {\r\n    background-color: blue;\r\n    border: 1px solid blue !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
